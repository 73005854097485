import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiModule, BASE_PATH } from 'lms-api';
import { environment } from 'src/environments/environment';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { CoreModule } from './core/core.module';
import { MyMaterialModule } from './material.module';
import { ErrorDialogService } from './error-dialog/errordialog.service';
import { AddPointsModalComponent } from '../app/layout/campaigns/campaign-detail/components/add-points-dialog/add-points-dialog.component';
import { ModaleSuccessComponent } from './common/modale-success/modale-success.component';
import { ModalSuccessActionsService } from './common/modale-success/modale-success-actions.service';
import { ModaleConfirmComponent } from './common/modale-confirm/modale-confirm.component';
import { ModalConfirmActionsService } from './common/modale-confirm/modale-confirm-actions.service';
import { ModaleXComponent } from './common/modale-x/modale-x.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';


@NgModule({
    declarations: [
        AppComponent,
        // FallbackComponent,
        // ShouldLoginComponent,
        ErrorDialogComponent,
        AddPointsModalComponent,
        ModaleSuccessComponent,
        ModaleConfirmComponent,
        ModaleXComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule.forRoot(),
        AppRoutingModule,
        ApiModule,
        MyMaterialModule,
        HttpClientModule,
    ],
    providers: [
        HttpClient,
        ErrorDialogService,
        ModalSuccessActionsService, 
        ModalConfirmActionsService,
        { provide: BASE_PATH, useValue: environment.lmsApiBasePath },
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
