<!-- <div>
  <div>
      <p>
          Reason: {{data.reason}}
      </p>
      <p>
          Status: {{data.status}}
      </p>
      <p>
        Message: {{data.message}}
    </p>
    <p>
      Ok: {{data.ok}}
    </p>
    <p>
      Error : {{data.error}}
    </p>

  </div>
</div> -->

<div id="modal-content-wrapper">
  <header id="modal-header">
      <h1 id="modal-title">Attenzione</h1>
  </header>
  <section id="modal-body">
    <p class="text-center">{{data.error}}</p>
  </section>
  <footer id="modal-footer">
    <a class="cancel-button"  (click)="closeModal()">
      OK
     </a>     
    
  </footer>
</div>