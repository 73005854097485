import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { UsersService } from 'lms-api';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BACKOFFICE_ROLE, OPERATORE_BL, SUPERADMIN_ROLE } from '../common/global-constants';
import { ErrorDialogService } from '../error-dialog/errordialog.service';
import { Claims } from '../interfaces/claims';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();


  private hasClaimsSubjct$ = new BehaviorSubject<boolean>(false);
  public hasClaims$ = this.hasClaimsSubjct$.asObservable();

  private claimsSubjct$ = new BehaviorSubject<object>(null);
  public claims$ = this.claimsSubjct$.asObservable();

  claims: any = {};

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl('/login');
  }

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private userService: UsersService,
    private errorDialogService: ErrorDialogService
  ) {
    // Useful for debugging:
    /* this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
      } else {
        console.warn('OAuthEvent Object:', event);
      }
    }); */

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        this.hasClaimsSubjct$.next(this.oauthService.getIdentityClaims() !== null);
        if (this.oauthService.getIdentityClaims() !== null) {
          this.claimsSubjct$.next(this.oauthService.getIdentityClaims());
        }
      });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());

    this.oauthService.events
      .pipe(filter(e => ['code_error'].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());



     // this.oauthService.setupAutomaticSilentRefresh();
  }

  public runInitialLoginSequence(): Promise<void> {
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this.oauthService.loadDiscoveryDocument()

      // For demo purposes, we pretend the previous call was very slow
    //  .then(() => new Promise(resolve => setTimeout(() => resolve(), 1000)))

      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      .then(() => this.oauthService.tryLogin())

      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        }

      })

      .then(() => {
        this.isDoneLoadingSubject$.next(true);
        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          this.router.navigateByUrl(stateUrl);
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }


  public loginFacebook() {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow('/dashboard');
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }


  public loginUser(username: string, password: string) {
    const url = environment.issuer + '/.well-known/openid-configuration';
    this.oauthService.loadDiscoveryDocument(url).then(() => {
    // Do what ever you want here
    this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(username, password).then(() => {
      // sessionStorage.setItem('isLoggedin', 'true');
      // this.jwtHelperService.decodeToken()
      this.claims = this.oauthService.getIdentityClaims() as Claims;
      if (this.claims != null) {
        // console.log(this.claims);
        sessionStorage.setItem('backOfficeUserId', this.claims.sub);
        this.userService.getUser(this.claims.sub).subscribe(user =>
          {       
                  
            if (user.roles.includes(BACKOFFICE_ROLE) || user.roles.includes(SUPERADMIN_ROLE)) {
              if (user.campaignIds.length === 1){
                sessionStorage.setItem('showFilters', 'none');
              }
              if (user.id === OPERATORE_BL) {
                sessionStorage.setItem('showOrders', 'true');
                sessionStorage.setItem('showUsers', 'false');
                sessionStorage.setItem('showReports', 'true');
              }
              else {
                sessionStorage.setItem('showOrders', 'true');
                sessionStorage.setItem('showUsers', 'true');
                sessionStorage.setItem('showReports', 'true');
              }
              this.router.navigateByUrl('/dashboard');
            }
            else {
              const data = {
                status: '',
                message : 'Non hai i permessi per accedere al backoffice'
            };
              this.errorDialogService.openDialog(data);
            }
          });
      }
      // this.router.navigateByUrl('/dashboard');
      });
    });
  }

  setSession(claims: any) {
    this.claims = claims;
    sessionStorage.setItem('userId', claims.sub);
    sessionStorage.setItem('username', claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']);
    sessionStorage.setItem('email', claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']);
    sessionStorage.setItem('id', claims.id);
  }

  public logout() { this.oauthService.logOut(); sessionStorage.clear(); this.router.navigateByUrl('/login'); }
  public refresh() { this.oauthService.silentRefresh(); }
  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }
  public getClaims() { return this.oauthService.getIdentityClaims(); }

  }
