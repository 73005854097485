<div id="modal-content-wrapper" *ngIf = "sent === false">
  <div class="filocolorato"></div>
    <section id="modal-body">
      <p>Non esiste una campagna associata al listino prezzi.<br>
        Compila il form sotto per inserire i riferimenti di una nuova campagna.</p>
         <footer id="modal-footer">
          <form class="filter-form" [formGroup] = 'formAddCampaign' >
                <mat-form-field class="full-width" >
                  <mat-label>Codice Campagna</mat-label>
                  <input matInput type="text" formControlName = "codiceCampagna">
                </mat-form-field><br>
                <mat-form-field class="full-width" >
                  <mat-label>Descrizione Campagna</mat-label>
                  <input matInput type="text" formControlName = "descrizioneCampagna">
                </mat-form-field><br>
                <mat-form-field class="full-width" >
                  <mat-label>Data inizio</mat-label>
                  <input matInput type="date" formControlName = "inizioCampagna">
                </mat-form-field>
                <mat-form-field class="full-width" >
                  <mat-label>Data fine</mat-label>
                  <input matInput type="date" formControlName = "fineCampagna">
                </mat-form-field>
        </form> 
      <button mat-raised-button color="primary" class="m-2"(click)="closeModal1()">     
        INVIA
      </button>
  
      
    </footer>
    </section>
  </div>
  

  <div id="modal-content-wrapper" *ngIf = "sent === true">
    <header id="modal-header">
      <h4 class="numberstitle" id="modal-title">COMPLIMENTI
       </h4>
    </header>
    <div class="filocolorato"></div>
    <section id="modal-body">
      <div class="text-block text-blu text-center">
        Listino inviato con successo, premi OK per continuare le modifiche
      </div>
    </section>
    <footer id="modal-footer">
     <button mat-raised-button color="primary" class="m-2"(click)="closeModal2()">     
        OK
      </button>
    </footer>
  </div>


  

  