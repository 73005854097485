<div id="modal-content-wrapper">
    <header id="modal-header">
      <h4 class="numberstitle" id="modal-title">{{ modalConfirmData.title }} 
        <br/> {{ modalConfirmData.present }}</h4>
    </header>
    <div class="filocolorato"></div>
    <section id="modal-body">
      <div class="text-block text-blu text-center">
        {{ modalConfirmData.message }}
      </div>
    </section>
    <footer id="modal-footer">    
      <button mat-raised-button color="accent" class="m-2" (click)="dialogRef.close(false)">     
        ANNULLA
      </button>
      <button mat-raised-button color="primary" class="m-2" (click)="dialogRef.close(true)">     
        OK
      </button>
    </footer>
  </div>


  
