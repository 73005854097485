import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransactionRuleResource, TransactionRulesService, WalletService } from 'lms-api';

@Component({
  selector: 'app-add-points',
  templateUrl: './add-points-dialog.component.html',
  styleUrls: ['./add-points-dialog.component.scss']
})
export class AddPointsModalComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  // transactionsList: Observable<TransactionRuleResource[]>;
  transactionsList: TransactionRuleResource[];
  formAddPoints: FormGroup;
  userLoggedIn = sessionStorage.getItem('backOfficeUserId');

  constructor(public dialogRef: MatDialogRef<AddPointsModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: any,
              private transactionRulesService: TransactionRulesService,
              private fb: FormBuilder,
              private walletService: WalletService
    ) {
  }

  ngOnInit() {
    this.buildAddPoints();
    this.transactionRulesService.getTransactionRulesByCampaign(this.modalData.campaigId).subscribe(transRules =>
      {
        this.transactionsList = transRules;
      }
    );
  }

  actionFunction() {
    // this.modalService.modalAction(this.modalData);
    this.walletService.updateWalletAsync(this.modalData.walletSelectedId, this.formAddPoints.value).subscribe(points => {
      this.passEntry.emit(points);
      this.closeModal();
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  public buildAddPoints(): void {
    this.formAddPoints = this.fb.group({
      transactionRuleId: ['', Validators.required],
      amount: ['', Validators.required],
      createdByUserId: [this.userLoggedIn]
    });
  }

}
