import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ModalSuccessActionsService {

  constructor(private router: Router,
    @Inject(DOCUMENT) private document: Document) { }

  modalAction(modalData: any) {
    switch (modalData.action) {
      case 'goToNewRoute':
        this.goToNewRoute(modalData);
        break;
      case 'reload':
        this.reload();
        break;
      default:
        break;
    }
  }

  private goToNewRoute(modalData: any) {
    //console.log(modalData.newRoute);
    this.router.navigateByUrl(modalData.newRoute);
  }

  private reload() {
    this.document.defaultView.location.reload();
  } 


}
