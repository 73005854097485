<div id="modal-content-wrapper">
    <header id="modal-header">
      <h4 class="numberstitle" id="modal-title">{{ modalSuccessData.title }} 
        <br/> {{ modalSuccessData.present }}</h4>
    </header>
    <div class="filocolorato"></div>
    <section id="modal-body">
      <div class="text-block text-blu text-center">
        {{ modalSuccessData.message }}
      </div>
    </section>
    <footer id="modal-footer">
     <button mat-raised-button color="primary" class="m-2"(click)="actionFunction()">     
        {{ modalSuccessData.actionButtonText }}
      </button>
    </footer>
  </div>


  
