<div class="">
    
    <router-outlet></router-outlet>

    <div class="overlay" *ngIf="!(isDoneLoading | async)">
      <div class="center">
        <mat-progress-spinner class="authenticating-loader"  color="primary" 
          diameter="100" mode="indeterminate" strokeWidth="true" value="50">
        </mat-progress-spinner>
      </div>
    </div>
    <!--<mat-progress-spinner class="authenticating-loader" *ngIf="!(isDoneLoading | async)" color="primary" 
      diameter="100" mode="indeterminate" strokeWidth="true" value="50">
    </mat-progress-spinner>-->
   <!-- <span *ngIf="!(isDoneLoading | async)" class="spinner-border spinner-border-sm mr-1 authenticating-loader"></span>-->
   <!-- <div class="authenticating-loader" *ngIf="!(isDoneLoading | async)"><div>Loading...</div></div> -->
 <!-- <div *ngIf="(isAuthenticated | async)">
      IdentityClaims<code id="identityClaims">{{identityClaims | json}}</code>
    <p>{{username}}</p>
  </div> -->
</div>

