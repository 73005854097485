<div id="modal-content-wrapper">
  <header id="modal-header">
      <h1 id="modal-title">Aggiungi punti</h1>
  </header>
  <section id="modal-body">
    <p>Seleziona la transazione sulla quale assegnare punti, inserire i punti da aggiungere e premere aggiungi</p>
    <form class="filter-form" [formGroup]="formAddPoints">
      <mat-form-field class="full-width">
        <mat-label>Transazione</mat-label>
        <mat-select formControlName="transactionRuleId">
          <mat-option *ngFor="let transaction of transactionsList" [value]="transaction.id">
            {{ transaction.name }} - {{ transaction.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Punti</mat-label>
        <input matInput type="text" formControlName="amount" required>
      </mat-form-field>
      
    </form>    
  </section>
  <footer id="modal-footer">
    
    <button mat-raised-button color="accent" class="filter m-2" (click)="closeModal()">     
      INDIETRO
    </button>
    <button mat-raised-button color="primary" class="filter" (click)="actionFunction()">     
      AGGIUNGI PUNTI
    </button>
    
    
  </footer>
</div>
