import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {CampaignsService, PricelistProposalService} from 'lms-api'
@Component({
  selector: 'app-modale-x',
  templateUrl: './modale-x.component.html',
  styleUrls: ['./modale-x.component.scss']
})
export class ModaleXComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModaleXComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private fb: FormBuilder,
    private route : ActivatedRoute,
    private router : Router,
    private campaignsService: CampaignsService,
    private priceListProposalService: PricelistProposalService) { }


    formAddCampaign: FormGroup;
    priceId;
    sent = false

  ngOnInit(): void {
    this.priceId = this.route.snapshot.paramMap.get('proposal-id');
    this.buildFormAddCampaign();
  }

  buildFormAddCampaign(): void {
    this.formAddCampaign = this.fb.group({
      codiceCampagna: [null],
      descrizioneCampagna: [null],
      inizioCampagna: [null],
      fineCampagna: [null]
    });

  }



  closeModal1() {
    this.priceListProposalService.sendXMLFilesToX(this.modalData.priceId, {campaignName: this.formAddCampaign.value.descrizioneCampagna, campaignCodeX: this.formAddCampaign.value.codiceCampagna, startDate: this.formAddCampaign.value.inizioCampagna, endDate: this.formAddCampaign.value.fineCampagna}).subscribe(ret =>{
      this.sent = true

    })

    
  }

  closeModal2(){
    this.dialogRef.close()
    window.location.reload();
  }
  

}
