import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalSuccessActionsService } from './modale-success-actions.service';

@Component({
  selector: 'app-modale-success',
  templateUrl: './modale-success.component.html',
  styleUrls: ['./modale-success.component.scss']
})
export class ModaleSuccessComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModaleSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public modalSuccessData: any,
    private modalService: ModalSuccessActionsService) {
}

  ngOnInit() {
  }

  actionFunction() {
    this.modalService.modalAction(this.modalSuccessData);

    this.closeModal();
    // this.reload();
    
  }

  closeModal() {
    this.dialogRef.close();
   // window.location.reload();
  }

}
